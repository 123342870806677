import React, { useState, useEffect, HTMLAttributes, useRef, Component, useMemo } from "react";
import { useId } from "@uifabric/react-hooks";
import "../../Custom.css";

import { useConstCallback, useBoolean } from "@uifabric/react-hooks";
import RGL, {Layout, ReactGridLayoutProps,  WidthProvider, WidthProviderProps, CoreProps } from "react-grid-layout";
import {
    MessageBar, Separator, Stack, IMessageBarStyles, DirectionalHint,
    IStyleFunctionOrObject,
} from "office-ui-fabric-react";

import ReactPaginate from "react-paginate";
import { ReactNotificationOptions, store } from "react-notifications-component";
import { getTypeName, InputType, TextBoxType } from "./Validations";
import Helper, { IAsyncSelect, IContentControls, IMyListOption } from "../../Helpers/Helper";
import Lists from "../Settings/Lists";
import ShareTemplate from "../CustomTemplates/ShareTemplate";

import {
    StackItem, IconButton, Modal, IModalStyles, IToggleStyleProps, Toggle,
    IToggleStyles, Coachmark, TeachingBubbleContent, IIconProps, Icon, FontWeights,
    getTheme, mergeStyleSets, DefaultButton, PrimaryButton, IButtonProps, Label, ILabelStyleProps,
    ILabelStyles, DialogFooter, Panel, PanelType, IDropdownOption, Spinner,
    SpinnerSize, Pivot, PivotItem, Link, IButtonStyles, IChoiceGroupOption, Text, FontSizes
} from "@fluentui/react";

import { Color } from "react-color";
import { EditorValue } from "react-rte";
import axios from "axios";
import { IListDetail } from "./CustomForm/Models/IListDetails";
import {
    formatFromUTC,
    setGlobalDateLocale,
} from "../../Helpers/DateUtils";
import HelpComponentRelative from "../Help/HelpComponentRelative";
import WizardPreview from "./WizardPreview";
import WizardService, { OperationsManualTagType } from "../../services/wizard";
import { useTranslation } from "react-i18next";
import HttpRequest from "../../services/HttpRequest";
import { DisplayType } from "../OutboundIntegration/Models/Enums";
import OutboundServices from "../OutboundIntegration/Services/OutboundServices";
import { EventModel } from "../OutboundIntegration/Models/EventModel";
import PropertyForm from "./CustomTemplate/Views/PropertyForm";
import WizardAddons from "./CustomTemplate/WizardAddons";
import ToolBox from "./CustomTemplate/WizardTolbox";
import WizardLayoutItem from "./CustomTemplate/WizardLayoutItem";
import { useModal } from "../../hooks/useModal";
import { ConditionModal } from "./CustomTemplate/formAssembly/ConditionModal";
import { Action } from "history";
import ContentControls from "../RHFControls/SingleRHFRichTextComponents/ContentControls";
import { DEFAULT_EDIT_MODE, FormRuleEditMode, IFormAssemblyRule } from "../../interfaces/IFormAssembly";
import { Sizes } from "../../share/CustomModal";
import { IStatusMessage } from "../../interfaces/IApp";
import EditModeBar from "./CustomTemplate/EditModeBar";
import WizardProvider from "./CustomTemplate/context/WizardContext";
import { useBranding } from "../../hooks/useBranding";
import AppService from "../../services/app";
import PropertiesMenu from "./PropertiesMenu";
import ListService, {IGetListMasterByUserRequest} from "../../services/List";
import {contentStyles, IContentStyles} from "../RenewalsAndAlerts/Style";
import { Theme } from "@fluentui/react-theme-provider";
import HelpComponent from "../Help/HelpComponent";

const ResponsiveReactGridLayout = WidthProvider(RGL);

const theme = getTheme();

const stackTokens = { childrenGap: 4 };

const iconButtonStyles = {
    root: {
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
        selectors: {
            ":hover": {
                cursor: "pointer",
            },
            ":disabled": {
                backgroundColor: theme.palette.white,
            },
        },
    },
    
};



const modalFormStyles: IModalStyles = {
    main: {
        width: "80vw",
        height: "800px",
    },
    root: {},
    scrollableContent: {},
    layer: {},
    keyboardMoveIcon: {},
    keyboardMoveIconContainer: {},
};

const customDropdownStyles = {
    control: (styles: any, state: any) => ({
        ...styles,
        borderRadius: 1,
        borderColor: state.isFocused ? "rgb(0, 120, 212)" : "rgb(96, 94, 92)",
        minHeight: 32,
    }),
    valueContainer: (styles: any) => ({
        ...styles,
        paddingTop: 0,
    }),
    menu: (styles: any) => ({
        ...styles,
        marginTop: 0,
    }),
    menuList: (styles: any) => ({
        ...styles,
        padding: 0,
    }),
};

const cancelIcon: IIconProps = { iconName: "Cancel" };
const saveIcon: IIconProps = { iconName: "Save" };
const previewIcon: IIconProps = { iconName: "PreviewLink" };
const pageIcon: IIconProps = { iconName: "PageAdd" };
const pageRIcon: IIconProps = { iconName: "PageRemove" };
const ExpIcon: IIconProps = { iconName: "EngineeringGroup" };
const PlusIcon: IIconProps = { iconName: "AppIconDefaultAdd" };

const options: IChoiceGroupOption[] = [
    { key: "0", text: "None" },
    { key: "1", text: "Mask" },
    { key: "2", text: "Pattern" },
];

const optionsAlign: IChoiceGroupOption[] = [
    { key: "0", text: "Left" },
    { key: "1", text: "Center" },
    { key: "2", text: "Right" },
];
const optionsFont: IChoiceGroupOption[] = [
    { key: "0", text: "Small" },
    { key: "1", text: "Medium" },
    { key: "2", text: "Large" },
];

const iconTagButtonStyles: Partial<IButtonStyles> = {
    root: { float: "right", height: "inherit", background: "transparent" },
    rootHovered: { background: "transparent" },
    rootPressed: { background: "transparent" },
    icon: { color: "#fff" },
    menuIcon: { color: "#fff" },
};

interface ILayoutBag {
    CanInsertRowAbove?: boolean
    CanInsertRowBelow?: boolean
}

export interface ILayout {
    Id: string;
    Label: string;
    Type: InputType;
    TextBoxType: TextBoxType;
    ItemList: string;
    Validations: Validation;
    AvoidValidations?: boolean;
    Layout: RGL.Layout;
    Page: number;
    TextParagraph: string;
    Children: ILayout[];
    ListId: number;
    TabIndex: number;
    AdminOnly: boolean;
    Tooltip: string;
    TooltipUrl: string;
    FontColor: string;
    BackgroundColor?: string;
    Align: string;
    FontSize: number;
    ShowScrollbar: boolean;
    IsBold: boolean;
    IsItalic: boolean;
    IsUnderlined: boolean;
    Placeholder: string;
    manualTag?: boolean;
    Integration?: Integration;
    Disabled?: boolean
    IsPickingList?: boolean;
    PickingList?: PickingList;
    Version?: number;
}

export interface PickingList {
    Integration: Integration;
}
export interface Integration {
    EventId?: number;
    Id: number;
    DisplayType: DisplayType;
}

/**
 * interface Validation
 */
export interface Validation {
    Required: boolean
    ReadOnly: boolean
    MaxLength: number
    Mask: string
    Regex: string
    DefaultValue: string
    ErrorMessage: string
    Formula?: string
    TodaysDate?: boolean
    Culture?: string
    Currency?: string
    CurrencySubstrate?: string
    Hide?:boolean
}
export const DEFAULT_VALIDATION_LAYOUT: Validation = {
    Required: false,
    ReadOnly: false,
    MaxLength: 1,
    Mask: '',
    Regex: ';',
    DefaultValue: '',
    ErrorMessage: '',
}
export const errorMessage = "Pattern did not match.";
export const emptyColor: Color = "#0000";
export const emptyBackgroundColor: Color = "#FFFF";
export const maxLength = 150;

function CustomTemplate(props: any) {
    const { branding } = useBranding()
    const [rteValue, setRteValue] = useState<{ [key: string]: EditorValue }>(
        {}
    );
    const { t } = useTranslation("wizard");

    const [itemProperties, setItemProperties] = useState<ILayout | undefined>(
        undefined
    );
    const emptyDropdown: IDropdownOption[] = [];
    const titleId = useId("CustomTemplate");
    const [layouts, setLayouts] = useState<ILayout[]>([]);
    const [layoutItems, setLayoutItems] = useState<ILayout[]>([]);
    const [toolBoxItems, setToolBoxItems] = useState<ILayout[]>([]);
    const [content, setContent] = useState<JSX.Element[]>();
    const [isOpen, setIsOpen] = useState<string | null>(null);
    const [selectedCC, setSelectedCC] = useState("");
    const [applying, setApplying] = useState(false);
    const [customDropDownLists, setCustomDropDownLists] = useState<{
        [key: string]: IDropdownOption[];
    }>({});
    const [cultures, setCultures] = useState<IDropdownOption[]>([])
    const [selectedType, setSelectedType] = useState<InputType>();


    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [nSeparator, setNSeparator] = useState(1);
    const [nHeader, setNHeader] = useState(1);
    const [nParagraph, setNParagraph] = useState(1);
    const [nAttachment, setNAttachment] = useState(1);
    const [nIntegration, setNIntegration] = useState(1);
    const [nHelpLink, setNHelpLink] = useState(1);

    const [pageCount, setPageCount] = useState(1);
    const [pageSelected, setPageSelected] = useState(1);

    const [modalListOpen, setModalListOpen] = useState(false);
    const [saved, setSaved] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [showSavingSpinner, setShowSavingSpinner] = useState(false);
    const [message, setMessage] = useState("Layout saved successfully!");

    const [showShare, setShowShare] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [myLists, setMyLists] = useState<IMyListOption[]>([]);
    const [selectedListId, setSelectedListId] = useState("");
    const [selectedList, setSelectedList] = useState<IMyListOption | null>(
        null
    );
    const [selectedIntegrationId, setSelectedIntegrationId] = useState("");
    const [selectedIntegration, setSelectedIntegration] =
        useState<IMyListOption | null>(null);
    const [lockItem, setLockItem] = useState(false);
    const [itemDragged, setItemDragged] = useState("");
    const targetButton = React.useRef<HTMLDivElement>(null);
    const targetButtonT = React.useRef<HTMLDivElement>(null);
    const targetButtonG = React.useRef<HTMLDivElement>(null);
    const targetButtonP = React.useRef<HTMLDivElement>(null);

    const gridLayoutMargin = 10
    const gridLayoutRowHeight = 35

    const [
        isCoachmarkVisible,
        { setFalse: hideCoachmark, setTrue: showCoachmark },
    ] = useBoolean(false);
    const [
        isCoachmarkVisibleT,
        { setFalse: hideCoachmarkT, setTrue: showCoachmarkT },
    ] = useBoolean(false);
    const [
        isCoachmarkVisibleG,
        { setFalse: hideCoachmarkG, setTrue: showCoachmarkG },
    ] = useBoolean(false);
    const [
        isCoachmarkVisibleP,
        { setFalse: hideCoachmarkP, setTrue: showCoachmarkP },
    ] = useBoolean(false);

    const [showSaveModal, setShowSaveModal] = useState(false);
    const [isModified, setIsModified] = useState(false);
    const [showSavePModal, setShowSavePModal] = useState(false);
    const [isModifiedP, setIsModifiedP] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);


    const [ccId, setCCId] = useState("");
    const [released, setReleased] = useState(false);


    const [unexpectedError, setUnexpectedError] = useState(false);

    const [audiences, setAudiences] = useState(emptyDropdown);

    const [listState, setListState] = useState(false);

    const [selectedDropDownGridList, setSelectedDropDownGridList] =
        useState<IMyListOption | null>(null);
    const [selectedItemGridList, setSelectedItemGridList] = useState("");

    const SecondaryButtonProps: IButtonProps = React.useMemo(
        () => ({
            children: "More info",
            onClick: goToHelp,
        }),
        []
    );

    const [operationsManualTags, setOperationsManualTags] = useState<
        OperationsManualTagType[]
    >([]);
    const [events, setEvents] = useState<EventModel[]>([]);
    const [formRules, setFormRules] = useState<IFormAssemblyRule[]>([])
    const [editMode, setEditMode] = useState<FormRuleEditMode>(DEFAULT_EDIT_MODE)

    const _styles = mergeStyleSets({
            body: {
                flex: '4 4 auto',
                padding: '0px 16px 16px 16px'
            }
        }
    )

     const contentStyles = (theme: Theme): any => {
        return mergeStyleSets({
            pagination: {
                padding: 0,
                selectors: {
                    "ul": {
                        display: "inline-block"
                    },
                    "li": {
                        display: "inline-block",
                        backgroundColor: branding.theme.palette.white,
                    },
                    "li a": {
                        color: branding.theme.palette.themeDarker,
                        fontSize: "12pt",
                        fontWeight: "500",
                        display: "inline-block",
                        padding: "4px 8px",
                        cursor: "pointer",
                        border: `solid 1px ${branding.theme.palette.themeDarker}`,
                        borderCollapse: "collapse",
                        userSelect: 'none'
                    }
                }
            },
            active: {
                backgroundColor: branding.theme.palette.themeDarker,
                selectors: {
                    "a": {
                        color: `${branding.theme.palette.white} !important`,
                        backgroundColor: branding.theme.palette.themeDarker,
                    },
                    "li a": {
                        color: branding.theme.palette.white,
                    },
                }
            },
            container: {
                display: "flex",
                flexFlow: "column nowrap",
                alignItems: "stretch",
            },
            correctHeader: [
                theme.fonts.xLarge,
                {
                    color: branding.theme.palette.black,
                    alignItems: "center",
                    fontWeight: FontWeights.semibold,
                },
            ],
            header: [
                theme.fonts.xLarge,
                {
                    flex: "1 1 auto",
                    borderTop: `4px solid ${branding.theme.palette.themePrimary}`,
                    borderCollapse: 'collapse',
                    color: theme.palette.black,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: FontWeights.semibold,
                    padding: "12px 12px 0px 24px",
                },
            ],
            body: {
                width: "95%",
                height: "75vh",
                flex: "4 4 auto",
                padding: "0 24px 24px 24px",
                selectors: {
                    p: {margin: "14px 0"},
                    "p:first-child": {marginTop: 0},
                    "p:last-child": {marginBottom: 0},
                },
            },
            bodyMail: {
                flex: "4 4 auto",
                padding: "0 24px 24px 24px",
                overflowY: "hidden",
                minHeight: 500,
            },
            containerMail: {
                display: "flex",
                flexFlow: "column nowrap",
                alignItems: "stretch",
                width: "50vw",
            },
            containerSaveModal: {
                display: "flex",
                flexFlow: "column nowrap",
                alignItems: "stretch",
                width: "30vw",
                height: "20vh",
            },
            panelHeaderSubtitle: {
                fontFamily: "Calibri",
                fontWeight: "bold",
                fontSize: 18,
            },
            callout: {
                width: 320,
                maxWidth: "90%",
                padding: "16px 8px",
            },
            labelHelp: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            },
            msLabel: {
                fontWeight: "600",
            },
            buttonBar: {
                display: "flex",
                justifyContent: "space-between",
            },
            wizard: {},
            savingSpinner: {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            },
            wizardContent: {
                display: "flex",
            },
            wizardToolbox: {
                flex: '0 0 auto',
                height: '65vh'
            },
            wizardLayout: {
                flex: '1'
            }
        })
    }

    const formConditionModal = useModal()

    const messageBarSuccessStyles: IMessageBarStyles = {
        root: {
            backgroundColor: "#acffac",
        },
    };
    const messageBarFailedStyles: IMessageBarStyles = {
        root: {
            backgroundColor: "#ffd5d5",
        },
    };

    let auxItems: ILayout[] = [];
    let auxToolBoxItems: ILayout[] = [];

    function getLabelStyles(props: ILabelStyleProps): ILabelStyles {
        return {
            root: {
                fontSize: 14,
                fontFamily: "Segoe UI Web (West European)",
                fontWeight: "normal",
            },
        };
    }

    const dismissPanelDel = () => {
        setSelectedCC("");
        setLockItem(false);
        setSelectedList(null);
        setSelectedIntegration(null);
        setIsModifiedP(false);

        // let previousItem = document.getElementById(
        //     "I" + sessionStorage.getItem("selected")
        // );
        // if (previousItem != null) previousItem!.className = "divGridItem";

        setIsOpen(null);
    };

    const dismissPanel = () => {
        // todo Currently the cancel modal is not working. We need to check this behavior.
        // if (isModifiedP) {
        //     setShowSavePModal(true);
        // } else {
            setSelectedCC("");
            setLockItem(false);
            setSelectedList(null);
            setSelectedIntegration(null);
            setIsModifiedP(false);

            // let previousItem = document.getElementById(
            //     "I" + sessionStorage.getItem("selected")
            // );
            //if (previousItem != null) previousItem!.className = "divGridItem";

            setIsOpen(null);
       // }
    };

    const togglePreview = useConstCallback((open: boolean) => {
        setModalPreviewOpen(open);
    });

    const toggleListModal = useConstCallback((open: boolean) => {
        setModalListOpen(open);
    });
    const getMyList = async () => {

        const request: IGetListMasterByUserRequest = {
            OwnerCoOwnerUser: props.username
        }

        await ListService.getListMasterByUser(request)
            .then((data) => {
                getAudiences(data)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
            })

    }

    const loadCulture = async () => {
        AppService.getCultures().then(({ data }: any) => {
            setCultures(() => {
                return data.map((culture: any) => {
                    return { key: culture.Key, text: culture.Value }
                    //  return { value: culture.Key, label: culture.Value }
                })
            })
        })
    }

    const loadOnLoadIntegrations = async () => {
        OutboundServices.GetOnLoadEvents({
            AccountId: Helper.getDefaultAccount(),
            ApiKey: "-1",
            Autoprovision: true,
            Path: await Helper.getSite(),
            SK: Helper.getSessionKey(),
            UserName: Helper.getUsername(),
            TemplateId: props.templateId,
        }).then((res) => {
            if (res.length > 0) {
                setEvents(res);
            }
        });
    };

    useEffect(() => {
        if (props.showFormBuilder) {
            if (
                props.culture !== null &&
                props.culture !== undefined &&
                props.culture !== ""
            )
                setGlobalDateLocale(props.culture);
            else setGlobalDateLocale(navigator.language);

            sessionStorage.setItem("layout", "");
            setLayouts([]);
            setSelectedCC("");
            sessionStorage.setItem("selected", "");
            setContent([]);
            setToolBoxItems([]);
            setFirstLoad(true);
            getMyList();
            loadOnLoadIntegrations();
            // loadCulture()
            if (props.isUpdate != null && props.isUpdate) loadTemplate();
            else {
                generateLayout();
            }
        }
    }, [props.showFormBuilder]);

    useEffect(() => {
        if (props.showFormBuilder) {
            if (
                props.culture !== null &&
                props.culture !== undefined &&
                props.culture !== ""
            )
                setGlobalDateLocale(props.culture);
            else setGlobalDateLocale(navigator.language);
        }
    }, [modalPreviewOpen]);

    useEffect(() => {
        setEditMode({ ...DEFAULT_EDIT_MODE })
        setOperationsManualTags([]);
        setFormRules([])
        setEvents([])
    }, [props.templateId]);

    useEffect(() => {
        if (isOpen && myLists.length > 0 && selectedListId !== "") {
            setSelectedList(() => {
                const filterValue = myLists.filter(
                    (f) => f.value === selectedListId
                )[0];
                return filterValue !== undefined ? filterValue : null;
            });
            setSelectedDropDownGridList(() => {
                const filterValue = myLists.filter(
                    (f) => f.value === selectedListId
                )[0];
                return filterValue !== undefined ? filterValue : null;
            });
        }
    }, [isOpen, myLists, selectedListId, listState]);

    const getAudiences = async (listsResponse: any) => {
        const { data }: any =
            await WizardService.getDocumentOnlyAudienceDetailByUserName({
                Path: props.siteSelected,
            });
        if (data !== undefined) {
            try {
                let auxAudiences: IDropdownOption[] = [];
                data.map((item: any) => {
                    let itemText = item.Name;
                    if (item.Name.trim().toLowerCase() === "default") {
                        if (item.Owner) {
                            itemText =
                                itemText +
                                " (" +
                                item.UserName +
                                "(" +
                                item.FullName +
                                ")" +
                                ")";
                        } else {
                            itemText =
                                itemText +
                                (item.OwnerFullName !== null
                                    ? " (" +
                                    item.OwnerUser +
                                    "(" +
                                    item.OwnerFullName +
                                    ")" +
                                    ")"
                                    : "");
                        }
                    } else {
                        itemText = itemText + " (" + item.Description + " )";
                    }
                    auxAudiences.push({
                        key: item.DocumentOnlyAudienceMasterId,
                        text: itemText,
                    });
                });
                setMyLists((prev) => {
                    let auxLists: IMyListOption[] = [];
                    listsResponse.map((x: any) => {
                        auxLists.push({
                            value: x.Id.toString(),
                            label:
                                x.Name.trim() + " (" + x.Description.trim() + ") " +
                                auxAudiences.find(
                                    (aud) => aud.key === x.DocumentOnlyAudienceMaster
                                )?.text,
                        });
                    });
                    return auxLists;
                });
                setAudiences(auxAudiences);
                setListState((prev) => !prev);
            } catch (error) {
                console.log(error);
                showNotification("An error occurred: " + error, "error");
            }
        }
    };

    function generateDOM(items: ILayout[]) {
        setApplying(false);
        setUnexpectedError(false);
    }

    function generateLayout() {
        let layoutArray: ILayout[] = [];
        let toolboxArray: ILayout[] = [];

        if (props.contentControls !== undefined) {
            for (let i = 0; i < props.contentControls.length; i++) {
                let children: ILayout[] = [];
                if (props.contentControls[i].children != null) {
                    props.contentControls[i].children.forEach(
                        (c: IContentControls) => {
                            children.push({
                                Id: c.tag,
                                Label: c.alias,
                                Children: [],
                                ListId: 0,
                                ItemList: "",
                                Layout: {
                                    x: 0,
                                    y: 0,
                                    w: 1,
                                    h: 2,
                                    i: c.tag,
                                    static: false,
                                },
                                Page: 0,
                                TextParagraph: "",
                                TabIndex: 0,
                                AdminOnly: false,
                                Tooltip: "",
                                TooltipUrl: "",
                                TextBoxType: TextBoxType.Normal,
                                Type: InputType.Textbox,
                                FontColor: "#000000",
                                FontSize: 14,
                                Align: "left",
                                ShowScrollbar: true,
                                Placeholder: "",
                                Validations: {
                                    MaxLength: maxLength,
                                    Mask: "",
                                    Required: false,
                                    ReadOnly: false,
                                    Regex: "",
                                    DefaultValue: "",
                                    ErrorMessage: errorMessage,
                                    TodaysDate: false,
                                },
                                IsBold: false,
                                IsItalic: false,
                                IsUnderlined: false,
                            });
                        }
                    );
                }
                let layout: ILayout = {
                    Id: props.contentControls[i].tag,
                    Label: props.contentControls[i].alias,
                    Type: InputType.Textbox,
                    TextBoxType: TextBoxType.Normal,
                    ItemList: "",
                    ListId: 0,
                    Validations: {
                        MaxLength: maxLength,
                        Mask: "",
                        Required: false,
                        ReadOnly: false,
                        Regex: "",
                        DefaultValue: "",
                        ErrorMessage: errorMessage,
                        TodaysDate: false,
                    },
                    Layout: {
                        x: 0,
                        y: 0,
                        w: 1,
                        h: 2,
                        i: props.contentControls[i].tag,
                        static: false,
                    },
                    Page: 0,
                    TextParagraph: "",
                    Children: children,
                    TabIndex: 0,
                    AdminOnly: false,
                    Placeholder: "",
                    Tooltip: "",
                    TooltipUrl: "",
                    FontColor: "#000000",
                    FontSize: 14,
                    Align: "left",
                    ShowScrollbar: true,
                    IsBold: false,
                    IsItalic: false,
                    IsUnderlined: false,
                };
                toolboxArray.push(layout);
            }

            saveLayout(toolboxArray);
        }
        setLayoutItems(layoutArray);
        setToolBoxItems(toolboxArray);
        setSelectedType(InputType.Textbox);
        setIsModified(true);
        saveLayoutOnStateManagement()
    }


    /**
     * Adds a blank space in the layout at the specified position ('above' or 'below').
     *
     * @param {Layout} layout - The layout object where the blank space will be added.
     * should be added above or below the specified layout.
     * @return {void} - This function does not return any value.
     */
    function onAddBlankSpace (layout:Layout):void {
        const updatedLayoutItems = layoutItems.map(item => {
            if (item.Layout.y >= layout.y) {
                return {
                    ...item,
                    Layout: {
                        ...item.Layout,
                        y:  item.Layout.y + 1
                    }
                };
            }
            return item
        })
        setLayoutItems(updatedLayoutItems)
    }

    /**
     * Adjusts the position of each layout item by removing blank spaces if the item's position
     * is below the specified layout's position.
     *
     * @param {Object} layout - The layout reference used to determine the blank space removal.
     * @param {number} layout.y - The y-coordinate of the layout reference.
     * @return {void} This function does not return a value.
     */
    function onRemoveBlankSpace (layout:Layout):void {
        const updatedLayoutItems = layoutItems.map(item => {
            if (item.Layout.y > layout.y) {
                return {
                    ...item,
                    Layout: {
                        ...item.Layout,
                        y: item.Layout.y - 1
                    }
                };
            }
            return item
        })
        setLayoutItems(updatedLayoutItems)
    }


    /**
     * Creates an interactive row element above the specified layout position in the given container.
     * The row allows the user to add a new row above the current position when clicked.
     *
     * @param {Layout} value - The layout object containing position and dimension data.
     * @param {HTMLCollectionOf<Element>} myContainer - The container to which the new row will be appended.
     * @return {void} This function does not return a value.
     */
    function CreateAddingRow(value:Layout, myContainer: HTMLCollectionOf<Element>): void {
        const posY = value.y

        const addRowLabel = document.createElement('i')
        addRowLabel.style.display = 'none'
        addRowLabel.style.cursor = 'pointer'
        addRowLabel.style.backgroundColor = branding.theme.palette.greenDark
        addRowLabel.style.color = branding.theme.palette.white
        addRowLabel.style.padding = "0px 8px"
        addRowLabel.innerText = '+ Add row'
        addRowLabel.onclick = () => {
            onAddBlankSpace(value)
        }

        const addRow = document.createElement('div')
        addRow.id = `react-grid-item-add-row-${posY}`
        addRow.className = 'react-grid-item-add-row'
        addRow.style.position = 'absolute'
        addRow.style.alignItems = 'center'
        addRow.style.justifyContent = 'center'
        addRow.style.display = 'flex'
        addRow.style.fontSize = FontSizes.small
        addRow.style.color = 'white'
        addRow.style.cursor = 'pointer'
        addRow.style.backgroundColor = 'transparent'
        addRow.style.boxSizing = 'border-box'

        addRow.onmouseover = () => {
            addRow.style.borderTop = `dotted 1px ${branding.theme.palette.black}`
            addRow.style.borderBottom = `dotted 1px ${branding.theme.palette.black}`
            addRow.style.backgroundColor = '#cbf7d6'
            addRowLabel.style.display = 'block'
        }
        addRow.onmouseout = () => {
            addRow.style.borderTop = 'none'
            addRow.style.borderBottom = 'none'
            addRow.style.background = 'none'
            addRow.childNodes.forEach((element) => {
                addRowLabel.style.display = 'none'
            })
        }

        addRow.style.transform = `translate(10px, ${(((value.h * gridLayoutRowHeight) * posY + (posY * gridLayoutMargin)) + 3)}px)`
        addRow.style.height = `4px`
        addRow.style.width = `calc(100% - 20px)`
        addRow.appendChild(addRowLabel)
        addRow.onclick = () => {
            onAddBlankSpace(value)
        }
        myContainer[0].appendChild(addRow)
    }


    /**
     * Creates a blank row element with a delete icon and appends it to the specified container.
     * The blank row has specific styles and event handlers for hover effects.
     *
     * @param {Layout} row - The layout configuration for the row, containing properties like height and position.
     * @param {HTMLCollectionOf<Element} myContainer
     * @return {void} This function does not return a value.
     */

    function CreateBlankRow(row:Layout, myContainer: HTMLCollectionOf<Element> ):void {
        const deleteIcon = document.createElement('i')
        deleteIcon.id = `deleteIcon_${row.y}`
        deleteIcon.className = 'ms-Icon ms-Icon--Delete'
        deleteIcon.style.cursor = 'pointer'
        deleteIcon.onclick = () => {
            onRemoveBlankSpace(row)
        }


        const blankRow = document.createElement('div')
        blankRow.className = 'react-grid-item-blank-row'
        blankRow.style.position = 'absolute'
        blankRow.style.alignItems = 'center'
        blankRow.style.justifyContent = 'center'
        blankRow.style.display = 'flex'
        blankRow.style.fontSize = FontSizes.size16
        blankRow.style.color = 'transparent'
        blankRow.onmouseover = () => {
            blankRow.style.backgroundColor = 'rgba(255, 230, 230, 0.5)'
            blankRow.style.color = branding.theme.palette.red
        }
        blankRow.onmouseout = () => {
            blankRow.style.background = 'none'
            blankRow.style.color = 'transparent'
        }

        blankRow.style.transform = `translate(10px, ${(((row.h * gridLayoutRowHeight) * row.y + (row.y * gridLayoutMargin))) + gridLayoutMargin}px)`
        blankRow.style.height = `35px`
        blankRow.style.width = `calc(100% - 20px)`

        blankRow.appendChild(deleteIcon)
        myContainer[0].appendChild(blankRow)
    }

    /**
     * Handles changes to the layout grid by updating the DOM with blank rows and rows that can be added*/
    function onLayoutGridChange(layout: RGL.Layout[]) {
        const test = document.getElementsByClassName('react-grid-layout')
        if(test !==  null && test.length > 0) {
            Array.from(test[0].getElementsByClassName('react-grid-item-add-row')).forEach((element) => {
                element.remove()
            })
            Array.from(test[0].getElementsByClassName('react-grid-item-blank-row')).forEach((element) => {
                element.remove()
            })
        }

        if(layout.length > 0) {
            // region Add blank spaces
            const bag = new Map<string, ILayoutBag>()
            const addAboveRows = new Map<number, Layout>()


            const maxYPosition = Math.max(...layout.map(item => item.y + item.h))


            layout.forEach((item) => {
                const itemY = item.y + item.h
                const candidateCollideAbove = layout.find(item2 => item2.y < item.y && (item2.y + item2.h) > item.y)
                const candidateCollideBelow = layout.find(item2 => item2.y < itemY && (item2.y + item2.h) > itemY)
                bag.set(item.i, {
                    CanInsertRowAbove: candidateCollideAbove === undefined,
                    CanInsertRowBelow: candidateCollideBelow === undefined
                })
                if (candidateCollideAbove === undefined && item.y < maxYPosition) {
                    addAboveRows.set(item.y, {
                        x: item.x,
                        y: item.y,
                        w: 4,
                        h: 1,
                        i: `AddAboveBlank-${item.y}`,
                        static: true
                    })
                }
                if (candidateCollideBelow === undefined && itemY < maxYPosition) {
                    addAboveRows.set(itemY, {
                        x: item.x,
                        y: itemY,
                        w: 4,
                        h: 1,
                        i: `AddAboveBlank-${itemY}`,
                        static: true
                    })
                }
            })

            addAboveRows.forEach((value, key) => {
                CreateAddingRow(value, test)
            })

            // endregion

            // region Render blank spaces
            const bag2 = new Array<Layout>()
            const maxY = layout.reduce((max, item) => Math.max(max, item.y), 0)
            for (let y = 0; y <= maxY; y++) {
                const candidateCollide = layout.find(item2 => item2.y <= y && (item2.y + item2.h) > y)
                if (candidateCollide === undefined) {
                    bag2.push({x: 0, y, w: 4, h: 1, i: `blank-${y}`, static: true})
                }
            }

            if (test !== null && test.length > 0) {
                bag2.forEach((row) => {
                    CreateBlankRow(row, test)
                })
            }
            // endregion
        }
    }

    function onLayoutChange(layout: RGL.Layout[]) {

        onLayoutGridChange(layout)

        if (!firstLoad) setIsModified(true);
        else setFirstLoad(false);

        let aux = layoutItems;

        aux.forEach((l) => {
            let i = layout.filter((x) => x.i === l.Id);
            if (i.length > 0) {
                l.Layout = i[0]
            }
        });

        setLayoutItems(aux);
        saveLayout(aux);
    }


    const getDefaultLayout = (
        id: string,
        label: string,
        manualTag: boolean,
        item?: ILayout
    ): ILayout => {
        return {
            Id: id,
            Label: label,
            Type: item?.Type ?? InputType.Textbox,
            TextBoxType: item?.Type ?? TextBoxType.Normal,
            ItemList: "",
            ListId: 0,
            Validations: {
                MaxLength: maxLength,
                Mask: "",
                Required: false,
                ReadOnly: false,
                Regex: "",
                DefaultValue: "",
                ErrorMessage: errorMessage,
                TodaysDate: false,
            },
            Layout: item?.Layout ?? {
                x: 0,
                y: 0,
                w: 1,
                h: 2,
                i: id,
                static: false,
            },
            Page: 0,
            TextParagraph: "",
            Children: [],
            TabIndex: 0,
            AdminOnly: false,
            Placeholder: "",
            Tooltip: "",
            TooltipUrl: "",
            FontColor: "#000000",
            FontSize: 14,
            Align: "left",
            ShowScrollbar: true,
            IsBold: false,
            IsItalic: false,
            IsUnderlined: false,
            manualTag: manualTag,
        } as ILayout;
    };

    function onTakeItem(ev: React.MouseEvent<HTMLElement>) {
        const t = ev?.currentTarget;
        let id = t?.id;

        setLayoutItems((prevs) => {
            let auxItems = prevs!;
            let itemToTake = auxItems.filter((x) => x.Id === id);

            let aux = itemToTake[0];
            aux.Page = 0;

            itemToTake[0].Layout.h = 2;
            itemToTake[0].Layout.w = 1;
            itemToTake[0].Layout.x = 0;
            itemToTake[0].Layout.y = 0;
            itemToTake[0].Layout.i = itemToTake[0].Id;
            itemToTake[0].Layout.static = false;
            itemToTake[0].Validations.Required = false;
            itemToTake[0].AdminOnly = false;
            itemToTake[0].Validations.ErrorMessage = "";

            itemToTake[0].Page = 0;

            auxItems?.splice(auxItems.indexOf(itemToTake[0]), 1);

            setToolBoxItems((prevs) => {
                let auxToolBoxItems = prevs;
                if (
                    !id.includes("SectionHeader") &&
                    !id.includes("LineBreak") &&
                    !id.includes("Paragraph") &&
                    !id.includes("Integration")
                )
                    auxToolBoxItems.push(itemToTake[0]);
                saveToolboxItems(auxToolBoxItems);
                return auxToolBoxItems;
            });
            dismissPanelDel();
            generateDOM(auxItems);

            return auxItems;
        });
    }



    function goToList() {
        toggleListModal(true);
    }
    async function loadTemplate() {
        setApplying(true);
        setUnexpectedError(false);
        const { data }: any = await WizardService.getStatemanagement({
            templateId: props.templateId.toString(),
            Path: props.siteSelected,
        });

        if (
            (data.Layout === undefined ||
                data.Layout === null ||
                data.Layout === "") &&
            props.isUpdate != null &&
            props.isUpdate
        ) {
            console.log(
                `Critical Error - Template Corrupted. Please Re-upload your orginal template.`
            );
            setUnexpectedError(true);
            setApplying(false);

            return;
        }
        sessionStorage.setItem("layout", data.Layout);
        setLayouts(JSON.parse(data.Layout) as ILayout[]);
        if (data.FormRules === undefined || data.FormRules === null || data.FormRules === "") {
            setFormRules([]);
        } else {
            // console.log("formAssembly", JSON.parse(data.FormRules) as IFormAssemblyRule[])
            setFormRules(JSON.parse(data.FormRules) as IFormAssemblyRule[]);
        }


        if (data.Layout !== "") {
            setLayouts(JSON.parse(data.Layout) as ILayout[]);
            let loadedLayout = JSON.parse(data.Layout) as ILayout[];
            let prevItems = loadedLayout.filter(
                (x) => x.Page.toString() === "1"
            );
            let toolItems = loadedLayout.filter(
                (x) => x.Page.toString() === "0"
            );
            setToolBoxItems(toolItems);
            setLayoutItems(prevItems);

            let secHeaders = loadedLayout.filter((x) =>
                x.Id.includes("SectionHeader")
            );
            if (secHeaders != null && secHeaders.length > 0) {
                let maxN = 0;
                secHeaders.forEach((sh) => {
                    let indx = sh.Id.substring(13, sh.Id.length);
                    if (parseInt(indx) > maxN) maxN = parseInt(indx);
                });
                setNHeader(maxN + 1);
            }

            let lineBreaks = loadedLayout.filter((x) =>
                x.Id.includes("LineBreak")
            );
            if (lineBreaks != null && lineBreaks.length > 0) {
                let maxN = 0;
                lineBreaks.forEach((ln) => {
                    let indx = ln.Id.substring(10, ln.Id.length);
                    if (parseInt(indx) > maxN) maxN = parseInt(indx);
                });
                setNSeparator(maxN + 1);
            }

            var integrations = loadedLayout.filter((x) =>
                x.Id.includes("Integration")
            );
            if (integrations != null && integrations.length > 0) {
                var maxN = 0;
                integrations.forEach((ln) => {
                    var indx = ln.Id.substring(12, ln.Id.length);
                    if (parseInt(indx) > maxN) maxN = parseInt(indx);
                });
                setNIntegration(maxN + 1);
            }

            var parag = loadedLayout.filter((x) => x.Id.includes("Paragraph"));

            if (parag != null && parag.length > 0) {
                let maxN = 0;
                parag.forEach((pa) => {
                    let indx = pa.Id.substring(9, pa.Id.length);
                    if (parseInt(indx) > maxN) maxN = parseInt(indx);
                });
                setNParagraph(maxN + 1);
            }

            let tempRichText: { [key: string]: EditorValue } = {};
            loadedLayout
                .filter((item) => item.Type === InputType.RichText)
                .forEach((item) => {
                    let richtTextValue: EditorValue;

                    tempRichText[item.Id] =
                        item.Validations.DefaultValue !== undefined &&
                            item.Validations.DefaultValue !== null &&
                            item.Validations.DefaultValue !== ""
                            ? EditorValue.createFromString(
                                item.Validations.DefaultValue,
                                "html"
                            )
                            : EditorValue.createEmpty();
                });

            setRteValue(tempRichText);
        } else setLayoutItems([]);

        setPageCount(parseInt(data.Pages));
        setReleased(data.Released);

        let e = document.getElementsByClassName(
            "react-grid-layout"
        ) as HTMLCollectionOf<HTMLElement>;

        for (let i = 0; i < e.length; i++) {
            e[i].setAttribute("id", "react-grid-layout");
        }

        preloadLists();
    }
    function openPreview() {
        togglePreview(true);
    }

    function preloadLists() {
        let pagePrevItems2 = JSON.parse(
            sessionStorage.getItem("layout")!
        ) as ILayout[];
        let prevItems2 = pagePrevItems2.filter(
            (x) =>
                x.Page.toString() === pageSelected.toString() &&
                x.ListId > 0 &&
                InputType.DropDownList === x.Type
        );
        let listIds: number[] = [];

        prevItems2.forEach((e) => {
            if (customDropDownLists[e.ListId] == undefined) {
                listIds.push(e.ListId);
            }
        });
        listIds = listIds.filter(
            (value, index, self) => self.indexOf(value) === index
        );

        const requests = listIds.map(async (listId) => {
            return ListService.GetListsDetails({DocumentOnlyListMasterId: String(listId)})
        });
        let tempCustomDropDownLists = customDropDownLists;
        Promise.all(requests)
            .then((result) => {
                result.forEach((responseListDetail) => {
                    if (responseListDetail.length === 0) {
                        return;
                    }
                    tempCustomDropDownLists[
                        responseListDetail[0].DocumentOnlyListMasterId
                    ] = responseListDetail.map<IDropdownOption>((item) => {
                        return {
                            key: item.Key.toString(),
                            text: item.Value,
                        };
                    });
                    setCustomDropDownLists(tempCustomDropDownLists);
                });
            })
            .finally(() => { });
    }

    function setTabOrder() {
        let layout = JSON.parse(sessionStorage.getItem("layout")!) as ILayout[];
        let max = 0;

        layout.forEach((e) => {
            if (e.Layout.y > max) max = e.Layout.y;
        });

        let maxPages = Math.max.apply(
            Math,
            layout.map(function (o) {
                return +o.Page;
            })
        );
        for (let iP = 1; iP <= maxPages; iP++) {
            let counter = 2;
            for (let iY = 0; iY <= max; iY++) {
                for (let iX = 0; iX < 4; iX++) {
                    let auxOrder = layout.filter(
                        (x) =>
                            x.Layout.x === iX &&
                            x.Layout.y === iY &&
                            x.Page.toString() === iP.toString()
                    );
                    if (auxOrder != null && auxOrder.length > 0) {
                        auxOrder.forEach((e) => {
                            e.TabIndex = counter;
                        });
                        counter++;
                    }
                }
            }
        }
        sessionStorage.setItem("layout", JSON.stringify(layout));
        setLayouts(layout);
    }
    function saveLayout(layout: ILayout[]) {
        if (
            sessionStorage.getItem("layout") != null &&
            sessionStorage.getItem("layout")!.length > 0
        ) {
            let auxLayout = JSON.parse(
                sessionStorage.getItem("layout")!
            ) as ILayout[];
            let otherItems = auxLayout.filter(
                (x) =>
                    x.Page.toString() !== pageSelected.toString() &&
                    x.Page.toString() !== "0"
            );
            let generalLayout = otherItems.concat(layout);
            sessionStorage.setItem("layout", JSON.stringify(generalLayout));
            setLayouts([...generalLayout]);
            saveToolboxItems(toolBoxItems);
        } else {
            sessionStorage.setItem("layout", JSON.stringify(layout));
            setLayouts([...layout]);
        }
    }
    function saveToolboxItems(layout: ILayout[]) {
        if (
            sessionStorage.getItem("layout") != null &&
            sessionStorage.getItem("layout")!.length > 0
        ) {
            let auxLayout = JSON.parse(
                sessionStorage.getItem("layout")!
            ) as ILayout[];
            let otherItems = auxLayout.filter((x) => x.Page.toString() !== "0");
            let generalLayout = otherItems.concat(layout);
            sessionStorage.setItem("layout", JSON.stringify(generalLayout));
            setLayouts(generalLayout);
        } else {
            sessionStorage.setItem("layout", JSON.stringify(layout));
            setLayouts(layout);
        }
    }
    async function saveLayoutOnStateManagement() {
        setShowSavingSpinner(true);
        const { data: saveCCData }: any =
            await WizardService.saveContentControls({
                TemplateId: Number(props.templateId),
                operations: operationsManualTags,
            });
        if (saveCCData.Success) {
            setTabOrder();
            let layout = JSON.parse(
                sessionStorage.getItem("layout") ?? "[]"
            ) as ILayout[];
            if (layout.length > 0) {
                layout = layout.map((item) => {
                    if (
                        item.Page === 0 &&
                        item.Children &&
                        item.Children?.length === 0
                    ) {
                        return getDefaultLayout(
                            item.Id,
                            item.Label,
                            item.manualTag ?? false
                        );
                    }
                    return item;
                });
            }
            sessionStorage.setItem("layout", JSON.stringify(layout));
            setLayouts(layout);
            const templateLayout = {
                TemplateId: props.templateId,
                Owner: props.username,
                Layout: JSON.stringify(layout),
                Pages: pageCount.toString(),
                Released: released,
                FormRules: JSON.stringify(formRules),
            };
            const { data }: any = await WizardService.saveStatemanagement({
                Path: props.siteSelected,
                TemplateId: props.templateId,
                User: "",
                Template: templateLayout,
            });

            if (data.Status === "Success") {
                setShowSavingSpinner(false);
                setMessage("Layout saved successfully!");
                setShowMessage(true);
                setSaved(true);
                setIsModified(false);
                setOperationsManualTags([]);
                if (props.isUpdate && props.templateEdit) {
                    Helper.SendNotification({
                        ActionByEmailAddress: props.username,
                        ActionDate: formatFromUTC(Date.now(), "ll", "en"),
                        AudienceName: props.audienceName,
                        AudienceOwnerEmailAddress: props.ownerUser,
                        RecordDescription: props.templateName,
                        TargetOfActionEmailAddress: "",
                        TemplateCategory: props.templateCategory,
                        TemplateName: props.templateName,
                        Type: "5",
                        Culture: props.culture,
                        UserName: props.username,
                        SK: props.sessionKey,
                        AccountId: props.accountId,
                        Path: props.siteSelected,
                        Autoprovision: true,
                        ApiKey: "-1",
                        EmailMoniker: props.emailMoniker,
                        TemplateId: props.templateId.toString(),
                    });
                }

                setTimeout(function () {
                    setShowMessage(false);
                }, 5000);
            } else {
                setShowSavingSpinner(false);
                setMessage(
                    data.ExceptionMessage.length > 0
                        ? data.ExceptionMessage
                        : "Something went wrong while saving the layout."
                );
                setShowMessage(true);
                setSaved(false);
                setTimeout(function () {
                    setShowMessage(false);
                }, 5000);
            }
        } else {
            setShowSavingSpinner(false);
            setMessage(saveCCData.ExceptionMessage);
            setShowMessage(true);
            setSaved(false);

            setTimeout(function () {
                setShowMessage(false);
            }, 5000);
        }
        setEditMode(DEFAULT_EDIT_MODE)
        const layoutArray = layoutItems.reduce((acc: RGL.Layout[], el) => acc.concat(el.Layout), [])
        onLayoutGridChange(layoutArray)
    }

    function showNotification(Nmessage: string, Ntype: string) {
        let auxType: ReactNotificationOptions;
        switch (Ntype) {
            case "success":
                store.addNotification({
                    title: "Success",
                    message: Nmessage,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true,
                    },
                });
                break;
            case "warning":
                store.addNotification({
                    title: "Warning",
                    message: Nmessage,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true,
                    },
                });
                break;
            case "error":
                store.addNotification({
                    title: "Error",
                    message: Nmessage,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true,
                    },
                });
                break;
            case "info":
                store.addNotification({
                    title: "Information",
                    message: Nmessage,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true,
                    },
                });
                break;
            case "successLeft":
                store.addNotification({
                    title: "Success",
                    message: Nmessage,
                    type: "success",
                    insert: "top",
                    container: "top-left",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true,
                    },
                });
                break;
            case "errorLeft":
                store.addNotification({
                    title: "Error",
                    message: Nmessage,
                    type: "danger",
                    insert: "top",
                    container: "top-left",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true,
                    },
                });
                break;
            default:
                break;
        }
    }

    function onAddSeparator(item: RGL.Layout) {
        let layoutArray: ILayout[] = [];
        let auxId = "LineBreak_" + nSeparator;

        layoutArray = layoutItems!;

        let layout: ILayout = {
            Id: auxId,
            Label: auxId,
            Type: InputType.LineBreak,
            TextBoxType: TextBoxType.None,
            ItemList: "",
            ListId: 0,
            Validations: {
                MaxLength: maxLength,
                Mask: "",
                Required: false,
                ReadOnly: false,
                Regex: "",
                DefaultValue: "",
                ErrorMessage: errorMessage,
                TodaysDate: false,
            },
            Layout: {
                x: item.x,
                y: item.y,
                w: item.w,
                h: 1,
                i: auxId,
                static: true,
            },
            Page: pageSelected,
            TextParagraph: "",
            Children: [],
            TabIndex: 0,
            AdminOnly: false,
            Placeholder: "",
            Tooltip: "",
            TooltipUrl: "",
            FontColor: "#000000",
            FontSize: 14,
            Align: "left",
            ShowScrollbar: true,
            IsBold: false,
            IsItalic: false,
            IsUnderlined: false,
        };
        layoutArray.push(layout);

        setNSeparator(nSeparator + 1);
        setLayoutItems(layoutArray);
        generateDOM(layoutItems);
    }
    function onAddHeader(item: RGL.Layout) {
        let layoutArray: ILayout[] = [];
        let auxId = "SectionHeader" + nHeader;

        layoutArray = layoutItems!;

        let layout: ILayout = {
            Id: auxId,
            Label: auxId,
            Type: InputType.SectionHeader,
            TextBoxType: TextBoxType.None,
            ItemList: "",
            ListId: 0,
            Validations: {
                MaxLength: maxLength,
                Mask: "",
                Required: false,
                ReadOnly: false,
                Regex: "",
                DefaultValue: "",
                ErrorMessage: errorMessage,
                TodaysDate: false,
            },
            Layout: {
                x: item.x,
                y: item.y,
                w: item.w,
                h: 1,
                i: auxId,
                static: true,
            },
            Page: pageSelected,
            TextParagraph: "",
            Children: [],
            TabIndex: 0,
            AdminOnly: false,
            Placeholder: "",
            Tooltip: "",
            TooltipUrl: "",
            FontColor: "#0062B1",
            BackgroundColor: emptyBackgroundColor.toString(),
            FontSize: 23,
            Align: "left",
            ShowScrollbar: true,
            IsBold: false,
            IsItalic: false,
            IsUnderlined: false,
        };

        layoutArray.push(layout);

        setNHeader(nHeader + 1);
        setLayoutItems(layoutArray);
        generateDOM(layoutItems);
    }
    function onAddParagraph(item: RGL.Layout) {
        let layoutArray: ILayout[] = [];
        let auxId = "Paragraph" + nParagraph;

        layoutArray = layoutItems!;

        let layout: ILayout = {
            Id: auxId,
            Label: auxId,
            Type: InputType.Paragraph,
            TextBoxType: TextBoxType.None,
            ItemList: "",
            ListId: 0,
            Validations: {
                MaxLength: maxLength,
                Mask: "",
                Required: false,
                ReadOnly: false,
                Regex: "",
                DefaultValue: "",
                ErrorMessage: errorMessage,
                TodaysDate: false,
            },
            Layout: {
                x: item.x,
                y: item.y,
                w: item.w,
                h: 2,
                i: auxId,
                static: true,
            },
            Page: pageSelected,
            TextParagraph: "",
            Children: [],
            TabIndex: 0,
            Placeholder: "",
            AdminOnly: false,
            Tooltip: "",
            TooltipUrl: "",
            FontColor: "#4D4D4D",
            FontSize: 18,
            Align: "left",
            ShowScrollbar: true,
            IsBold: false,
            IsItalic: false,
            IsUnderlined: false,
        };

        layoutArray.push(layout);

        setNParagraph(nParagraph + 1);
        setLayoutItems(layoutArray);
        generateDOM(layoutItems);
    }
    function onAddAttachment(item: RGL.Layout) {
        let layoutArray: ILayout[] = [];
        let auxId = "Attachment_" + nAttachment;

        layoutArray = layoutItems!;

        let layout: ILayout = {
            Id: auxId,
            Label: auxId,
            Type: InputType.Attachment,
            TextBoxType: TextBoxType.None,
            ItemList: "",
            ListId: 0,
            Validations: {
                MaxLength: maxLength,
                Mask: "",
                Required: false,
                ReadOnly: false,
                Regex: "",
                DefaultValue: "",
                ErrorMessage: errorMessage,
                TodaysDate: false,
            },
            Layout: {
                x: item.x,
                y: item.y,
                w: item.w,
                h: 2,
                i: auxId,
                static: true,
            },
            Page: pageSelected,
            TextParagraph: "",
            Children: [],
            TabIndex: 0,
            AdminOnly: false,
            Tooltip: "",
            Placeholder: "",
            TooltipUrl: "",
            FontColor: "#000000",
            FontSize: 14,
            Align: "left",
            ShowScrollbar: true,
            IsBold: false,
            IsItalic: false,
            IsUnderlined: false,
        };
        layoutArray.push(layout);

        setNAttachment(nAttachment + 1);
        setLayoutItems(layoutArray);
        generateDOM(layoutItems);
    }
    function onAddHelpLink(item: RGL.Layout) {
        let layoutArray: ILayout[] = [];
        let auxId = "HelpLink_" + nHelpLink;

        layoutArray = layoutItems!;

        let layout: ILayout = {
            Id: auxId,
            Label: auxId,
            Type: InputType.HelpLink,
            TextBoxType: TextBoxType.None,
            ItemList: "",
            ListId: 0,
            Validations: {
                MaxLength: maxLength,
                Mask: "",
                Required: false,
                ReadOnly: false,
                Regex: "",
                DefaultValue: "",
                ErrorMessage: errorMessage,
                TodaysDate: false,
            },
            Layout: {
                x: item.x,
                y: item.y,
                w: item.w,
                h: 2,
                i: auxId,
                static: true,
            },
            Page: pageSelected,
            TextParagraph: "",
            Children: [],
            TabIndex: 0,
            AdminOnly: false,
            Tooltip: "",
            Placeholder: "",
            TooltipUrl: "",
            FontColor: "#000000",
            FontSize: 14,
            Align: "left",
            ShowScrollbar: true,
            IsBold: false,
            IsItalic: false,
            IsUnderlined: false,
        };
        layoutArray.push(layout);

        setNHelpLink(nHelpLink + 1);
        setLayoutItems(layoutArray);
        generateDOM(layoutItems);
    }

    function onAddIntegration(item: RGL.Layout) {
        var layoutArray: ILayout[] = [];
        var auxId = "Integration_" + nIntegration;

        layoutArray = layoutItems!;
        // const layout = getDefaultLayout(auxId, auxId, false, {
        //     Type: InputType.Integration,
        //     TextBoxType: TextBoxType.None,
        //     Layout: {
        //         x: item.x,
        //         y: item.y,
        //         w: item.w,
        //         h: 2,
        //         i: auxId,
        //         static: true,
        //     },
        //     Page: pageSelected,
        // } as ILayout)
        var layout: ILayout = {
            Id: auxId,
            Label: auxId,
            Type: InputType.Integration,
            TextBoxType: TextBoxType.None,
            ItemList: "",
            ListId: 0,
            Validations: {
                MaxLength: maxLength,
                Mask: "",
                Required: false,
                ReadOnly: false,
                Regex: "",
                DefaultValue: "",
                ErrorMessage: errorMessage,
                TodaysDate: false,
            },
            Layout: {
                x: item.x,
                y: item.y,
                w: item.w,
                h: 2,
                i: auxId,
                static: true,
            },
            Page: pageSelected,
            TextParagraph: "",
            Children: [],
            TabIndex: 0,
            AdminOnly: false,
            Tooltip: "",
            Placeholder: "",
            TooltipUrl: "",
            FontColor: "#000000",
            FontSize: 14,
            Align: "left",
            ShowScrollbar: true,
            IsBold: false,
            IsItalic: false,
            IsUnderlined: false,
        };
        layoutArray.push(layout);

        setNIntegration(nIntegration + 1);
        setLayoutItems(layoutArray);
        generateDOM(layoutItems);
    }

    function onAddPage(ev: React.MouseEvent<HTMLElement>) {
        if(pageCount < 12)  {
            setPageCount(pageCount + 1);
            setIsModified(true);
            setFirstLoad(false);    
        }
        else  {
            setMessage("You’ve reached the maximum amount of pages for this Application.")
            setShowMessage(true)
            setSaved(false);
            setTimeout(function () {
                setShowMessage(false);
            }, 5000);
            
                
        }
    }
    function onRemovePage(ev: React.MouseEvent<HTMLElement>) {
        setIsModified(true);
        setFirstLoad(false);
        let layout = JSON.parse(sessionStorage.getItem("layout")!) as ILayout[];

        const excludeTypes = ['SectionHeader', 'LineBreak', 'Paragraph', 'Integration']
        const auxItems = layout.map((item) => {
            const page = Number(item.Page)
            if (page === pageSelected && !excludeTypes.find(x => item.Id.includes(x))) {
                return { ...item, Page: 0, Layout: { ...item.Layout, h: 1, w: 1, x: 0, y: 0 } }
            }
            if (page > pageSelected) {
                return { ...item, Page: page - 1 }
            }
            return item
        })
        saveLayoutData(auxItems)
        setIsModifiedP(false);
        dismissPanel();
        generateDOM(layoutItems);
        pageChange({ selected: pageCount === pageSelected ? pageSelected - 2 : pageSelected - 1 })

        setPageCount(pageCount - 1);
    }

    function pageChange(value: { selected: number }) {
        setApplying(true);
        setUnexpectedError(false);
        let selected = value.selected + 1;
        setPageSelected(selected);

        let layout = JSON.parse(sessionStorage.getItem("layout")!) as ILayout[];
        let pageItems = layout.filter(
            (x) => x.Page.toString() === selected.toString()
        );
        setLayoutItems(pageItems);
    }
    const saveLayoutData = (layout: ILayout[]) => {
        setToolBoxItems(() => layout.filter(x => Number(x.Page) === 0));
        setLayoutItems(layout);
        setLayouts(layout)
        sessionStorage.setItem('layout', JSON.stringify(layout))
    }
    const hideShare = () => {
        setModalTitle("");
        setShowShare(false);
    };


    function onChangeReleased(
        ev: React.MouseEvent<HTMLElement>,
        checked?: boolean
    ) {
        setReleased(checked!);
        setIsModified(true);
    }
    function onChangeBlockCC(
        ev: React.MouseEvent<HTMLElement>,
        checked?: boolean
    ) {
        const t = ev?.currentTarget;
        let el = t?.id;
        let id = el.slice(1);

        let layout = JSON.parse(sessionStorage.getItem("layout")!) as ILayout[];
        let pageItems = layout.filter(
            (x) => x.Page.toString() === pageSelected.toString()
        );
        let auxItem = pageItems.filter((x) => x.Layout.i === id);
        let index = 0;

        if (auxItem != null && auxItem.length > 0) {
            index = layout!.indexOf(auxItem[0]);
            auxItem[0].Layout.static = checked;

            layout[index] = auxItem[0];
        }
        setLayoutItems(pageItems);
        generateDOM(layoutItems);
    }


    function onDrop(layout: RGL.Layout[], item: RGL.Layout, e: Event) {
        onPutItemDrop(item);
    }

    function onPutItemDrop(item: RGL.Layout) {
        let id = itemDragged;

        if (
            id !== "LineBreak" &&
            id !== "Paragraph" &&
            id !== "Section" &&
            id !== "Attachment" &&
            id !== "HelpLink" &&
            id !== "Integration"
        ) {
            auxToolBoxItems = toolBoxItems!;
            auxItems = layoutItems!;
            let itemToPut = auxToolBoxItems.filter((x) => x.Id === id);

            if (itemToPut.length > 0) {
                let aux = itemToPut[0];
                aux.Page = pageSelected;
                itemToPut[0].Page = pageSelected;
                aux.Layout.i = aux.Id;
                aux.Layout.x = item.x;
                aux.Layout.y = item.y;
                aux.Layout.h = 2;
                aux.Layout.static = true;
                aux.Validations.MaxLength = maxLength;
                aux.Validations.ErrorMessage = errorMessage;

                if (aux.Label.endsWith("List")) {
                    aux.Layout.w = 4;
                    aux.Layout.h = 6;
                    aux.Type = InputType.GridList;
                }

                auxItems.push(itemToPut[0]);

                auxToolBoxItems?.splice(
                    auxToolBoxItems.indexOf(itemToPut[0]),
                    1
                );

                setLayoutItems(auxItems);

                setToolBoxItems(auxToolBoxItems);
                generateDOM(layoutItems);
            }
        } else {
            switch (id) {
                case "LineBreak":
                    onAddSeparator(item);
                    break;
                case "Paragraph":
                    onAddParagraph(item);
                    break;
                case "Section":
                    onAddHeader(item);
                    break;
                case "Attachment":
                    onAddAttachment(item);
                    break;
                case "HelpLink":
                    onAddHelpLink(item);
                    break;
                case "Integration":
                    onAddIntegration(item);
                    break;
            }
        }
    }

    function goToHelp() {
        window.open(`${branding.helpPath}`, "_blank");
    }

    useEffect(() => {
        generateDOM(layoutItems);
    }, [layoutItems]);

    const cancelSaveModal = (option: number) => {
        switch (option) {
            case 1:
                setIsModified(false);
                setShowSaveModal(false);
                saveLayoutOnStateManagement();
                break;

            case 2:
                setShowSaveModal(false);
                break;

            case 3:
                setShowSaveModal(false);
                setIsModified(false);

                setFirstLoad(true);
                setLayoutItems([]);
                setToolBoxItems([]);
                setContent([]);
                setSelectedCC("");
                setPageCount(1);
                setLockItem(false);
                setPageSelected(1);
                setNHeader(1);
                setNParagraph(1);
                setNSeparator(1);
                setNAttachment(1);
                setNHelpLink(1);
                setNIntegration(1);
                setReleased(false);
                setGlobalDateLocale(navigator.language);
                props.toggleFormBuilder(false);
                const site = sessionStorage.getItem("site")!;
                setSelectedItemGridList("");
                setSelectedDropDownGridList(null);
                props.reloadApp(site);

                break;
        }
        console.log('cancelSaveModal')
        setEditMode(DEFAULT_EDIT_MODE as FormRuleEditMode)
    };
    const closeModal = () => {
        if (isModified) {
            setShowSaveModal(true);
        } else {
            setFirstLoad(true);
            setLayoutItems([]);
            setToolBoxItems([]);
            setContent([]);
            setSelectedCC("");
            setPageCount(1);
            setLockItem(false);
            setPageSelected(1);
            setNHeader(1);
            setNParagraph(1);
            setNSeparator(1);
            setReleased(false);
            setSelectedItemGridList("");
            setSelectedDropDownGridList(null);
            setGlobalDateLocale(navigator.language);
            props.toggleFormBuilder(false);
            const site = sessionStorage.getItem("site")!;
            props.reloadApp(site);
            setEditMode({ ...DEFAULT_EDIT_MODE })
        }
    }
    const handleFormAssemblySteps = () => {
        formConditionModal.open()
    }
    /**
        * Rendering footer
        */

    useEffect(() => {
        if (itemProperties !== undefined) {
            setApplying(true);
            setUnexpectedError(false);
            setIsModified(true);
            setIsModifiedP(false);

            let oldItem = layoutItems!.filter(
                (x) => x.Id === itemProperties.Id
            );
            let index = layoutItems!.indexOf(oldItem[0]);

            oldItem[0] = itemProperties;

            layoutItems![index] = oldItem[0];
            saveLayout(layoutItems);
            setLayoutItems(layoutItems);

            setTimeout(function () {
                generateDOM(layoutItems);
                setItemProperties(undefined);
            }, 500);
        }
    }, [itemProperties]);

    return (
        <WizardProvider templateId={props.templateId} templateName={props.templateId} siteSelected={props.templateId} items={layouts}
            formRules={formRules} editMode={editMode} setEditMode={setEditMode}
            setFormRules={setFormRules} setToolBoxItems={setToolBoxItems} >
            <Modal
                isOpen={props.showFormBuilder}
                isBlocking={true}

                styles={{ main: {width: "80vw"}}}
            >

                {/*-- START HEADER --*/}
                <Stack horizontal className={contentStyles(branding.theme).header} tokens={{childrenGap: 8}} styles={{root: {height: 56}}}>
                    <Stack grow={1}
                           styles={{root: {overflow: "hidden"}}}>
                        <Text variant={"xLarge"} id={titleId} block
                              styles={{root: {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}}
                              title={`Application (${props.templateName})`}>
                            {`Application (${props.templateName})`}
                        </Text>
                    </Stack>
                    <Stack grow={1} horizontal tokens={{childrenGap: 8}} styles={{root: {padding: 8}}}>
                        <Stack>
                            <Toggle
                                id="btnRealesed"
                                checked={released}
                                onChange={onChangeReleased}
                                title={
                                    released ? "Released." : "Not released."
                                }
                            />
                        </Stack>
                        <Stack grow={1}>
                            <Text
                                variant={"small"}
                                styles={{root: {fontWeight: FontWeights.semibold}}}>{"Drag controls from the left panel onto the palette where you want them located. Use the gear icon to set the controls properties."}</Text>
                            <Text variant={"small"} styles={{
                                root: {
                                    fontWeight: FontWeights.semibold,
                                    color: released ? "black" : "red"
                                }
                            }}>{"(When ready, toggle to active to indicate application is ready for use)"}</Text>
                        </Stack>

                    </Stack>
                    <Stack horizontal wrap={false} disableShrink>
                        <HelpComponentRelative
                            headline={"Form Wizard Designer"}
                            text={"Use Form Wizard designer to create input forms for your documents.\nInput controls are derived from templates you upload.\nThe input controls are then arranged on the designer palette.\n\nOnce finished with design, use the wizard to capture data and generate documents."}
                            location="applicationlibrary"
                        />

                        <IconButton
                            iconProps={cancelIcon}
                            ariaLabel={t('Common.Button.Close', {ns: 'common'})}
                            onClick={() => {
                                closeModal();
                            }}
                            title={t('Common.Button.Close', {ns: 'common'})}
                        />

                    </Stack>
                    
                </Stack>
               <Separator />

                {showSavingSpinner &&
                    <Stack grow={true} styles={{root: {justifyContent: 'center', height: '80vh'}}}>
                        <Spinner
                            size={SpinnerSize.medium}
                            label="Saving changes..."
                            ariaLive="assertive"
                        />
                    </Stack>
                }


                {!showSavingSpinner &&
                    <Stack grow={true} horizontal styles={{root: {height: '80vh'}}}>
                        <Stack styles={{
                            root: {
                                width: 280,
                                maxWidth: 280,
                                height: '100%',
                                borderRight: `solid 1px ${branding.theme.palette.neutralLight}`
                            }
                        }}>
                            <ToolBox items={toolBoxItems} setItemDragged={setItemDragged}
                                     templateId={props.templateId} openPreview={openPreview}
                                     templateName={props.templateName}
                                     siteSelected={props.siteSelected} targetButtonT={targetButtonT}
                                     onAddPage={onAddPage}
                                     unexpectedError={unexpectedError}
                                     saveLayoutOnStateManagement={saveLayoutOnStateManagement}
                                     onRemovePage={onRemovePage} pageCount={pageCount} pageSelected={pageSelected}
                                     getDefaultLayout={getDefaultLayout}
                                     setToolBoxItems={setToolBoxItems} saveToolboxItems={saveToolboxItems}
                                     setOperationsManualTags={setOperationsManualTags}
                                     handleFormAssemblySteps={handleFormAssemblySteps}
                                     operationsManualTags={operationsManualTags} layouts={layouts}
                                     templateCategory={props.templateCategory}
                            />
                        </Stack>
                        <Stack grow={true} styles={{root: {height: '100%'}}} tokens={{padding: 4}}>
                            <Stack grow={true} styles={{root: {justifyContent: applying ? 'center' : 'initial'}}}>


                                {showMessage &&
                                    <MessageBar styles={
                                        saved ? messageBarSuccessStyles : messageBarFailedStyles
                                    }>
                                        {message}
                                    </MessageBar>
                                }

                                {applying &&
                                    <Spinner
                                        size={SpinnerSize.medium}
                                        label="Applying changes..."
                                        ariaLive="assertive"
                                    />
                                }

                                {!applying && <>

                                    {unexpectedError ?
                                        <>
                                            <MessageBar styles={messageBarFailedStyles}>
                                                {"Critical Error - Template Corrupted. Please Re-upload your orginal template."}

                                            </MessageBar>
                                        </>
                                        :
                                        <>
                                            <Stack >
                                                <EditModeBar handleFormAssemblySteps={handleFormAssemblySteps}/>
                                                <div ref={targetButtonG}></div>
                                            </Stack>
                                            <Stack grow={true} className="scrollVisible"
                                                   styles={{
                                                       root: {
                                                           overflow: "auto",
                                                           overflowX: "hidden",
                                                           height: '0px'
                                                       }
                                                   }}

                                            >

                                                <div>


                                                    <ResponsiveReactGridLayout
                                                        className="form-wizard-layout"
                                                        layout={
                                                            layoutItems.reduce((acc: RGL.Layout[], el) => acc.concat(el.Layout), [])
                                                        }
                                                        cols={4}
                                                        margin={[gridLayoutMargin, gridLayoutMargin]}
                                                        rowHeight={gridLayoutRowHeight}
                                                        style={{
                                                            minHeight:
                                                                "500px",
                                                            paddingBottom:
                                                                "50px",
                                                        }}
                                                        useCSSTransforms={true}
                                                        onLayoutChange={onLayoutChange}
                                                        isResizable={false}
                                                        onDrop={onDrop}
                                                        isDroppable={true}
                                                        preventCollision={true}
                                                        compactType={null}
                                                        droppingItem={{
                                                            h: itemDragged === "Integration" || itemDragged === "Paragraph" ? 2 : 1,
                                                            i: "ID",
                                                            w: 1,
                                                        }}
                                                    >

                                                        {layoutItems.map(function (control) {
                                                            return (
                                                                <div key={control.Id}>
                                                                    <WizardLayoutItem
                                                                        isOpen={isOpen === `L${control.Id}`}
                                                                        key={control.Id} control={control}
                                                                        onChangeBlockCC={onChangeBlockCC}
                                                                        onTakeItem={onTakeItem}
                                                                        setIsOpen={setIsOpen}/>
                                                                </div>
                                                            )
                                                        })}
                                                    </ResponsiveReactGridLayout>
                                                </div>
                                            </Stack>
                                        </>
                                    }
                                </>

                                }

                            </Stack>
                            <Stack styles={{root: {alignItems: 'end', paddingRight: 8}}}>
                                <div ref={targetButtonP}>
                                    <ReactPaginate
                                        previousLabel={pageCount > 1 ? "previous" : null}
                                        nextLabel={pageCount > 1 ? "next" : null}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        forcePage={pageSelected - 1}
                                        onPageChange={pageChange}
                                        containerClassName={contentStyles(branding.theme).pagination}
                                        activeClassName={contentStyles(branding.theme).active}
                                        pageLinkClassName={"indexTab"}
                                        previousLinkClassName={"indexTab"}
                                        nextLinkClassName={"indexTab"}
                                    />
                                </div>
                            </Stack>

                        </Stack>
                    </Stack>
                }
            </Modal>
                
                
                <formConditionModal.Modal title={`${editMode.rule?.ruleName ?? 'New rule'} - Form Assembly`} titleId="formAsemblyRuleConditionModal" size={Sizes.smPlus} bodyClassName={_styles.body}>
                    <ConditionModal close={formConditionModal.close} />
                </formConditionModal.Modal>
                

                <PropertyForm templateId={props.templateId}
                    siteSelected={props.siteSelected}
                    setSelectedList={setSelectedList}
                    setSelectedIntegrationId={setSelectedIntegrationId}
                    setShowSavePModal={setShowSavePModal}
                    setSelectedIntegration={setSelectedIntegration}
                    setSelectedItemGridList={setSelectedItemGridList}
                    setCCId={setCCId}
                    isOpen={isOpen}
                    dismissPanel={dismissPanel}
                    setSelectedCC={setSelectedCC}
                    selectedList={selectedList}
                    goToList={goToList}
                    setIsOpen={setIsOpen}
                    setLockItem={setLockItem}
                    setSelectedDropDownGridList={setSelectedDropDownGridList}
                    selectedCC={selectedCC}
                    auxItems={auxItems}
                    setApplying={setApplying}
                    setRteValue={setRteValue}
                    setUnexpectedError={setUnexpectedError}
                    setIsModified={setIsModified}
                    saveLayout={saveLayout}
                    setLayoutItems={setLayoutItems}
                    selectedItemGridList={selectedItemGridList}
                    rteValue={rteValue}
                    generateDOM={generateDOM}
                    preloadLists={preloadLists}
                    ccId={ccId}
                    selectedType={selectedType}
                    setSelectedType={setSelectedType}
                    setIsModifiedP={setIsModifiedP}
                    myLists={myLists}
                    events={events}
                    selectedDropDownGridList={selectedDropDownGridList}
                    setSelectedListId={setSelectedListId}
                    selectedIntegration={selectedIntegration}
                    lockItem={lockItem}
                    showSavePModal={showSavePModal}
                    titleId={titleId}
                    cancelSaveModal={cancelSaveModal}
                    setItemProperties={setItemProperties}
                    layoutItems={layoutItems} />

                {modalPreviewOpen ? (
                    <WizardPreview
                        modalPreviewOpen={modalPreviewOpen}
                        togglePreview={togglePreview}
                        layouts={layouts}
                        Username={props.username}
                        templateId={props.templateId}
                        templateName={props.templateName}
                        pageCount={pageCount}
                        released={released}
                        pageSelected={pageSelected}
                    />
                ) : null}



                <ShareTemplate
                    showModal={showShare}
                    showShare={showShare}
                    hideModal={hideShare}
                    modalTitle={modalTitle}
                    spinnerText={"Loading content..."}
                    TemplateId={props.templateId}
                    DocumentOnlyAudienceId={props.DocumentOnlyAudienceId}
                />
                {isCoachmarkVisible && (
                    <Coachmark
                        target={targetButton.current}
                        positioningContainerProps={{
                            directionalHint: DirectionalHint.rightCenter,
                        }}
                    >
                        <TeachingBubbleContent
                            headline="Aditional controls"
                            hasCloseButton
                            closeButtonAriaLabel={t("Common.Button.Close", {
                                ns: "common",
                            })}
                            primaryButtonProps={
                                sessionStorage.getItem("entityId") ===
                                    "Not In Teams"
                                    ? SecondaryButtonProps
                                    : undefined
                            }
                            onDismiss={hideCoachmark}
                        >
                            Drag and drop into layout area to create a new component
                            for each type.
                        </TeachingBubbleContent>
                    </Coachmark>
                )}
                {isCoachmarkVisibleT && (
                    <Coachmark
                        target={targetButtonT.current}
                        positioningContainerProps={{
                            directionalHint: DirectionalHint.rightCenter,
                        }}
                    >
                        <TeachingBubbleContent
                            headline="Content controls"
                            hasCloseButton
                            closeButtonAriaLabel={t("Common.Button.Close", {
                                ns: "common",
                            })}
                            primaryButtonProps={
                                sessionStorage.getItem("entityId") ===
                                    "Not In Teams"
                                    ? SecondaryButtonProps
                                    : undefined
                            }
                            onDismiss={hideCoachmarkT}
                        >
                            Drag and drop a specific content control into layout
                            area to set properties.
                        </TeachingBubbleContent>
                    </Coachmark>
                )}
                {isCoachmarkVisibleG && (
                    <Coachmark
                        target={targetButtonG.current}
                        positioningContainerProps={{
                            directionalHint: DirectionalHint.bottomCenter,
                        }}
                    >
                        <TeachingBubbleContent
                            headline="Layout Area"
                            hasCloseButton
                            closeButtonAriaLabel={t("Common.Button.Close", {
                                ns: "common",
                            })}
                            primaryButtonProps={
                                sessionStorage.getItem("entityId") ===
                                    "Not In Teams"
                                    ? SecondaryButtonProps
                                    : undefined
                            }
                            onDismiss={hideCoachmarkG}
                        >
                            Set properties to content control clicking on name or
                            gear icon. Drag and drop to change the position of the
                            selected content control.
                        </TeachingBubbleContent>
                    </Coachmark>
                )}
                {isCoachmarkVisibleP && (
                    <Coachmark
                        target={targetButtonP.current}
                        positioningContainerProps={{
                            directionalHint: DirectionalHint.topCenter,
                        }}
                    >
                        <TeachingBubbleContent
                            headline="Page Navigation"
                            hasCloseButton
                            closeButtonAriaLabel={t("Common.Button.Close", {
                                ns: "common",
                            })}
                            primaryButtonProps={
                                sessionStorage.getItem("entityId") ===
                                    "Not In Teams"
                                    ? SecondaryButtonProps
                                    : undefined
                            }
                            onDismiss={hideCoachmarkP}
                        >
                            The number of pages created for layout.
                        </TeachingBubbleContent>
                        setPageCount
                    </Coachmark>
                )}

                <Modal
                    isOpen={modalListOpen}
                    isBlocking={true}
                    containerClassName={contentStyles(branding.theme).containerMail}
                >
                    <div className={contentStyles(branding.theme).header}>
                        <span id={titleId}>My Lists</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={() => {
                                getMyList();
                                toggleListModal(false);
                            }}
                            title="Close."
                        />
                    </div>

                    <div className={contentStyles(branding.theme).bodyMail}>
                        <div className="ms-Grid">
                            <Lists
                                toggleSettings={props.toggleSettings}
                                siteOptions={props.siteOptions}
                                updateSiteSelected={props.updateSiteSelected}
                                modalTitle={"My Lists"}
                                templateList={props.templateList}
                                layoutLoaded={true}
                                siteSelected={Helper.getSiteSelected()}
                                accountId={Helper.getDefaultAccount()}
                                username={Helper.getUsername()}
                                sessionKey={Helper.getSessionKey()}
                                fromModal={true}
                            />
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={showSaveModal}
                    isBlocking={true}
                    containerClassName={contentStyles(branding.theme).containerSaveModal}
                >
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div
                                style={{ padding: "0px" }}
                                className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                            >
                                <div className={contentStyles(branding.theme).header}>
                                    <span id={titleId}>{"Unsaved Changes"}</span>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={() => {
                                            cancelSaveModal(2);
                                        }}
                                        title="Close."
                                    />
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-lg12">
                                <div className={contentStyles(branding.theme).bodyMail}>
                                    {props.isUpdate != null && props.isUpdate
                                        ? "Changes detected. Are you sure you want to exit?"
                                        : "You must save the layout first before exit wizard."}
                                </div>
                            </div>
                        </div>
                        <div
                            className="ms-Grid-row"
                            style={{
                                bottom: "10px",
                                right: "12px",
                                position: "absolute",
                            }}
                        >
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <DialogFooter>
                                    {props.isUpdate != null && props.isUpdate ? (
                                        <PrimaryButton
                                            onClick={() => {
                                                cancelSaveModal(3);
                                            }}
                                            text="Yes"
                                            title="Close."
                                        />
                                    ) : null}
                                    <DefaultButton
                                        onClick={() => { cancelSaveModal(2); }}
                                        text={props.isUpdate != null && props.isUpdate ? "No" : "Close"}
                                        title="Cancel exit."
                                    />
                                </DialogFooter>
                            </div>
                        </div>
                    </div>
                </Modal>
           
        </WizardProvider>
    );
}

export default CustomTemplate;
